








































import { Component, Vue } from "vue-property-decorator";
import DashPacientesModule from "@/store/modules/Dashboards/Empresarial/DashPacientes-module";
import { DashColors } from "../Dashcolors";
@Component({
  components: {
    ChartAltaPacientes: () => import("./Pacientes/ChartAltaPacientes.vue"),
    PieChart: () => import("@/components/Charts/PieChart.vue")
  }
})
export default class DashAgenda extends Vue {
  public created() {
    DashPacientesModule.getAltasDepacienteses();
    DashPacientesModule.getHombreMujeres();
    DashPacientesModule.getAgrupacion();
  }
  public get source1() {
    return DashPacientesModule.HombresMujeres;
  }
  public get source2() {
    return DashPacientesModule.Agrupacion.temporada;
  }
  public get source3() {
    return DashPacientesModule.Agrupacion.alimentacion;
  }
  public get source4() {
    return DashPacientesModule.Agrupacion.turno_laboral;
  }
  public get source5() {
    return DashPacientesModule.Agrupacion.actividad_fisica;
  }
  public get source6() {
    return DashPacientesModule.Agrupacion.practica_deporte;
  }
  public get source7() {
    return DashPacientesModule.Agrupacion.objetivo;
  }
  public get paleta() {
    return DashColors.palettecanadian;
  }
}
