import { API } from '@/shared/api';
import { AltasDepacientes } from '@/shared/dtos/DashboardsDtos/AltasDepacientes';
import { DashAgrupacionPorDato } from '@/shared/dtos/DashboardsDtos/DashAgrupacionPorDato';
import { DashTipoDatoPorcen } from '@/shared/dtos/DashboardsDtos/DashTipoDatoPorcen';
import { ssmHttpService } from '@/shared/services/http-service';
import { store } from '@/store/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    namespaced: true,
    name: 'DashPacientesModule',
    store,
    dynamic: true
})
class DashPacientesModule extends VuexModule {
    public AltasDepacientes: AltasDepacientes = new AltasDepacientes();
    public HombresMujeres: DashTipoDatoPorcen[] = [];
    public Agrupacion: DashAgrupacionPorDato = new DashAgrupacionPorDato();
    @Action({ commit: 'onGetAltasDepacientes' })
    public async getAltasDepacienteses() {
        return await ssmHttpService.get(API.AltasDepacientes);
    }

    @Mutation
    public onGetAltasDepacientes(res: AltasDepacientes) {
        this.AltasDepacientes = new AltasDepacientes(res);
    }

    @Action({ commit: 'onGetHombreMujeres' })
    public async getHombreMujeres() {
        return await ssmHttpService.get(API.TotalHombresMujeres);
    }

    @Mutation
    public onGetHombreMujeres(res: DashTipoDatoPorcen[]) {
        this.HombresMujeres = res ? res.map((x) => new DashTipoDatoPorcen(x)) : [];
    }

    @Action({ commit: 'onGetAgrupacion' })
    public async getAgrupacion() {
        return await ssmHttpService.get(API.AgrupacionPorDatos);
    }

    @Mutation
    public onGetAgrupacion(res: DashAgrupacionPorDato) {
        this.Agrupacion = new DashAgrupacionPorDato(res);
    }
}
export default getModule(DashPacientesModule);