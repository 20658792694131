import { BaseDto } from '@/shared/dtos/base-dto';
import { DashTipoDatoPorcen } from './DashTipoDatoPorcen';

export class DashAgrupacionPorDato extends BaseDto {
    public temporada !: DashTipoDatoPorcen[];
    public alimentacion !: DashTipoDatoPorcen[];
    public turno_laboral !: DashTipoDatoPorcen[];
    public actividad_fisica !: DashTipoDatoPorcen[];
    public practica_deporte !: DashTipoDatoPorcen[];
    public objetivo !: DashTipoDatoPorcen[];
} 
